<template lang="pug">
  div.flex.horizontal-expand.horizontal-centralize.vertical-centralize()
      div.flex.horizontal-expand.expand-vh-vertical(style="min-height: 100vh; display: flex; width: 100%; justify-content: center;")
        v-flex.pa-3.px-0(xs12 v-if="passo===1")
            v-form(ref="form")
                div.flex.horizontal-expand.horizontal-centralize.vertical-centralize.column
                    div.flex.horizontal-expand.column
                        v-flex(xs12)
                            span.fonte.black--text Nome Completo
                        v-flex(xs12)
                            v-text-field(
                                dense
                                
                                v-model="get_checkout.payer.nome"
                                placeholder="Digite o seu nome completo"
                                :rules="[v => !!v || 'Preencha aqui']"
                                :color="$theme.primary"
                                solo-inverted
                                flat
                            )
                    div.flex.horizontal-expand.column
                        v-flex(xs12)
                            span.fonte.black--text E-mail
                        v-flex(xs12)
                            v-text-field(
                                dense
                                v-model="get_checkout.payer.email"
                                placeholder="Informe o seu melhor e-mail"
                                :rules="[v => !!v || 'Preencha aqui']"
                                :color="$theme.primary"
                                solo-inverted
                                flat
                            )
                    div.flex.horizontal-expand.column
                        v-flex(xs12)
                            span.fonte.black--text Telefone
                        v-flex(xs12)
                            v-text-field(
                                dense
                                v-model="get_checkout.payer.telefone"
                                placeholder="Informe o seu telefone"
                                :rules="[v => !!v || 'Preencha aqui']"
                                :color="$theme.primary"
                                solo-inverted
                                v-mask="['(##) #####-####', '(##) ####-####']"
                                flat
                            )
                    div.flex.horizontal-expand.column
                        v-flex(xs12)
                            span.fonte.black--text Nascimento
                        v-flex(xs12)
                            v-text-field(
                                dense
                                v-model="get_checkout.payer.birth_date"
                                placeholder="Informe o seu nascimento"
                                :rules="[v => !!v || 'Preencha aqui']"
                                :color="$theme.primary"
                                solo-inverted
                                v-mask="['##/##/####']"
                                flat
                            )
                    div.flex.horizontal-expand.column
                        v-flex(xs12)
                            span.fonte.black--text CPF
                        v-flex(xs12)
                            v-text-field(
                                dense
                                v-model="get_checkout.payer.cpf"
                                placeholder="000.000.000-00"
                                :rules="[v => !!v || 'Preencha aqui']"
                                v-mask="['###.###.###-##']"
                                :color="$theme.primary"
                                solo-inverted
                                flat
                            )
                    div.flex.horizontal-expand.column
                      v-flex(xs12)
                          span.fonte.black--text Sexo
                      v-flex(xs12)
                          v-select(
                              dense
                              v-model="get_checkout.payer.sex"
                              :rules="[v => !!v || 'Preencha aqui']"
                              :color="$theme.primary"
                              solo-inverted
                              :items="sex_options"
                              item-text="name"
                              item-value="value"
                              flat
                          )
                    div.flex.horizontal-expand.column
                      v-flex(xs12)
                          span.fonte.black--text Etnia
                      v-flex(xs12)
                          v-select(
                              dense
                              v-model="get_checkout.payer.ethnicity"
                              :rules="[v => !!v || 'Preencha aqui']"
                              :color="$theme.primary"
                              solo-inverted
                              :items="ethnicity_options"
                              flat
                          )
                    
                    div.flex.horizontal-expand.column
                      v-flex(xs12)
                          span.fonte.black--text Endereço
                      v-flex(xs12)
                          v-text-field(
                              dense
                              v-model="get_checkout.payer.address.street"
                              :rules="[v => !!v || 'Preencha aqui']"
                              :color="$theme.primary"
                              solo-inverted
                              flat
                          )
                    div.flex.horizontal-expand.column
                      v-flex(xs12)
                          span.fonte.black--text Complemento
                      v-flex(xs12)
                          v-text-field(
                              dense
                              v-model="get_checkout.payer.address.complement"
                              :rules="[v => !!v || 'Preencha aqui']"
                              :color="$theme.primary"
                              solo-inverted
                              flat
                          )
                    div.flex.horizontal-expand.column
                      v-flex(xs12)
                          span.fonte.black--text Bairro
                      v-flex(xs12)
                          v-text-field(
                              dense
                              v-model="get_checkout.payer.address.neighborhood"
                              :rules="[v => !!v || 'Preencha aqui']"
                              :color="$theme.primary"
                              solo-inverted
                              flat
                          )
                    div.flex.horizontal-expand.column
                      v-flex(xs12)
                          span.fonte.black--text Cidade
                      v-flex(xs12)
                          v-text-field(
                              dense
                              v-model="get_checkout.payer.address.city"
                              :rules="[v => !!v || 'Preencha aqui']"
                              :color="$theme.primary"
                              solo-inverted
                              flat
                          )
                    div.flex.horizontal-expand.column
                      v-flex(xs12)
                          span.fonte.black--text UF do Estado
                      v-flex(xs12)
                          v-text-field(
                              dense
                              v-model="get_checkout.payer.address.state"
                              :color="$theme.primary"
                              solo-inverted
                              flat
                          )
                    div.flex.horizontal-expand.column
                      v-flex(xs12)
                          span.fonte.black--text CEP
                      v-flex(xs12)
                          v-text-field(
                              dense
                              v-model="get_checkout.payer.address.zipcode"
                              :rules="[v => !!v || 'Preencha aqui']"
                              :color="$theme.primary"
                              solo-inverted
                              flat
                          )
                    div.flex.horizontal-expand.column
                      v-flex(xs12)
                        span.fonte.black--text Senha para login
                      v-flex(xs12)
                        v-text-field(
                            dense
                            v-model="get_checkout.payer.senha"
                            :rules="[v => !!v || 'Preencha este campo']"
                            placeholder="******"
                            type="password"
                            :color="$theme.primary"
                            solo-inverted
                            flat
                        )

                    div.flex.horizontal-expand.column
                      v-flex(xs12)
                          span.fonte.black--text Confirme a Senha para login
                      v-flex(xs12)
                          v-text-field(
                              dense
                              
                              v-model="confirm_senha"
                              :rules="[v => !!v || 'Preencha aqui']"
                              placeholder="******"
                              type="password"
                              :color="$theme.primary"
                              solo-inverted
                              flat
                          )
                    
                    div.flex.horizontal-expand.column
                      v-flex(xs12)
                        span.fonte.red--text {{ erro }}
                        

                    //- div.mb-6.expande-horizontal.centraliza
                    //-   v-switch(class="pa-2"  color="light-green" outlined v-model="termos" label="Ao criar a conta você concorda com os nossos termos de uso.")
                    div.mb-6.expande-horizontal.centraliza
                      v-btn.fonte(
                        :disabled="senhaOk ? false : true"
                        rounded
                        depressed
                        outlined
                        class="fonte"
                        @click="valida_form"
                        :color="$theme.primary"
                      ) 
                        span.fonte Finalizar e Cadastrar
                    
                    div.mb-6.flex.horizontal-expand.column
                      v-btn.fonte(
                          @click="$router.push('/')"
                          text
                          block
                          
                          :color="$theme.primary"
                      )
                        span.black--text Tem um cadastro? Faça Login

                    //- h3.margin-vertical.mb-12.fonte.text-center Ao criar uma conta, você concorda com a nossa Política de Privacidade.
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import validarCPF from "validar-cpf";

export default {
props: ["sponsor_id"],
data() {
  return {
    passo: 1,
    loading: false,
    modal: false,
    termos: true,
    erro: "",
    confirm_senha: "",
    sex_options: [
      {
        name: 'Masculino',
        value: 'M'
      },
      {
        name: 'Feminino',
        value: 'F'
      }
    ],
    ethnicity_options: [
      'Branca', 
      'Não Informado', 
      'Preta', 
      'Parda', 
      'Amarela', 
      'Indígena'
    ],
    payload: {
      transaction_senha: "",
      senha: "",
      sponsor: {
        _id: "nosponsor"
      },
      address: {}
    },
    senhaOk: false
  };
},
computed: {
  ...mapGetters(["get_checkout"])
},
watch: {
  confirm_senha(val) {
    if (val === this.get_checkout.payer.senha) {
      this.senhaOk = true;
      this.erro = "";
    } else {
      this.senhaOk = false;
      this.erro = "Senhas não correspondem";
    }
  }
},
methods: {
  ...mapActions(["createUser", "createGlobalMessage", "checkout"]),
  valida_form() {
    this.loading = true;
    if (this.$refs.form.validate()) {
      if (validarCPF(this.get_checkout.payer.cpf)) {
        this.get_checkout.seller = this.$route.query.s_id;
        console.log('see', this.get_checkout)
        this.checkout();
      } else {
        this.createGlobalMessage({
          timeout: 6000,
          type: "error",
          message: "CPF inválido"
        });
      }
    }
  }
}
};
</script>
