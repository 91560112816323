<template lang="pug">
v-flex(xs12)
  div.expande-horizontal.centraliza.wrap
    v-flex(xs12)
      div(style="background: #fff;border-radius: 6px;").cool-shaddow.expande-horizontal.wrap
        v-app-bar(style="border-top-right-radius: 6px;border-top-left-radius: 6px;" height="50" flat :color="$theme.third")
          v-icon(:size="18" color="#f2f2f2").mr-3 mdi-shield-check-outline
          h4(style="color: #f2f2f2;").fonte COMPRA 100% SEGURA
        div.expande-horizontal.pa-3.wrap
          v-list(three-line)
            v-list-item.fonte.pa-0.ma-0
              v-avatar(rounded size="120")
                v-img(contain src="/logo.svg")
              v-list-item-content 
                v-list-item-title
                  //- span.font-weight-bold {{ get_checkout.plan._id ? get_checkout.plan.name : 'Carregando...' }}
                  span.font-weight-bold Seu plano
                v-list-item-subtitle
                  h2.font-weight-light {{ $helper.formataSaldo(get_checkout.plan.value_cents) }} {{ dependentValues }}
                v-list-item-subtitle(v-if="trial")
                  v-icon(color="green darken-2", size="16").mr-1 mdi-star
                  span.fonte.checkout-item-subtitle.green--text.font-mini(style="font-size: 9pt;") R$ 20,00 adicional por dependente.
          v-flex(xs12)
            div.expande-horizontal
              span.grey--text.fonteMini Adicione dependentes.
          v-flex(xs12)
            div.expande-horizontal
              v-btn.blue(dark @click="remove_dependent")
                v-icon mdi-minus
              v-spacer
              h2.font-weight-bold.fontme {{ get_checkout.dependents || 0 }}
              v-spacer
              v-btn.blue(dark @click="add_dependent")
                v-icon mdi-plus
            
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      trial: true
    };
  },
  computed: {
    ...mapGetters(["get_checkout"]),
    dependentValues() {
      if (!this.get_checkout.dependents) return '';
      let sumDependents = this.get_checkout.dependents * 20
      return `+ ${this.$helper.formataSaldo(sumDependents)}`
    }
  },
  methods: {
    add_dependent() {
      if (this.get_checkout.dependents) {
        this.$store.commit('set_checkout', {
          ...this.get_checkout,
          dependents: this.get_checkout.dependents += 1 
        })        
      } else {
        this.$store.commit('set_checkout', {
          ...this.get_checkout,
          dependents: 1 
        })      
      }
    },
    remove_dependent() {
      if(this.get_checkout.dependents && this.get_checkout.dependents > 0) {
        this.$store.commit('set_checkout', {
          ...this.get_checkout,
          dependents: this.get_checkout.dependents -= 1 
        })  
      } else [
        this.$store.commit('set_checkout', {
          ...this.get_checkout,
          dependents: 0
        })   
      ]
    }
  }
};
</script>
